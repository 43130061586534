ion-modal {
  &.auto-height {
    --height: auto;
    --max-height: 100%;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    ::part(content) {
      overflow: auto;
    }
  }

  &.auto-width {
    --width: auto;
    --max-width: 100%;

    .ion-page {
      position: relative;
      display: flex;
      justify-content: flex-start;
      contain: content;
    }

    ::part(content) {
      overflow: auto;
    }
  }

  &.full-screen-modal {
    --height: 100%;
    --width: 100%;
  }

  &.ion-sheet-modal-auto-height {
    --height: auto;
    --background: transparent;
  }

  &.right-edge {
    --height: 100%;
    --width: 420px;
    justify-content: flex-end;
  }

  &.product-search-modal {
    --min-height: 586px;
    --max-width: 100%;
    --width: 832px;
  }

  &.video-modal {
    --width: 70%;
  }

  &.mea-alert {
    --height: auto;
    --width: 400px;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }

  &.enduser-feedback-modal {
    ::part(content) {
      background-color: var(--mea-black-800);
      border-radius: 23px;
    }

    @media only screen and (width < $medium) {
      align-items: flex-end;

      .modal-shadow,
      ::part(content) {
        min-height: 320px;
        border-radius: 23px 23px 0 0;
      }
    }
  }

  &.chat-image-slides-modal {
    @media only screen and (min-width: $medium) {
      --width: 100%;
      --height: 100%;
    }
  }

  &.dataconsent-modal {
    @media only screen and (min-width: $medium) {
      --border-radius: var(--mea-border-radius-presentational);
    }
  }
}
