.chat-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;

  app-chat {
    display: flex;
    flex: 1 1 100%;
    overflow: hidden;
  }
}
