ion-toast.ion-toast-styling {
  --background: var(--mea-black-primary);
  --button-color: var(--mea-white);
  --border-radius: var(--mea-border-radius-interactive);
  --color: var(--mea-white);
  --start: var(--mea-spacing-16px);
  --end: var(--mea-spacing-16px);

  &::part(button) {
    font-size: var(--mea-font-size-s);
    font-weight: var(--mea-font-weight-bold);
  }
}
