/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('open-sans-v18-latin-regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('open-sans-v18-latin-regular.woff2') format('woff2'), url('open-sans-v18-latin-regular.woff') format('woff'),
    url('open-sans-v18-latin-regular.ttf') format('truetype'),
    url('open-sans-v18-latin-regular.svg#OpenSans') format('svg');
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('open-sans-v18-latin-italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('open-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
    url('open-sans-v18-latin-italic.woff2') format('woff2'), url('open-sans-v18-latin-italic.woff') format('woff'),
    url('open-sans-v18-latin-italic.ttf') format('truetype'),
    url('open-sans-v18-latin-italic.svg#OpenSans') format('svg');
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('open-sans-v18-latin-600.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'),
    url('open-sans-v18-latin-600.woff2') format('woff2'), url('open-sans-v18-latin-600.woff') format('woff'),
    url('open-sans-v18-latin-600.ttf') format('truetype'), url('open-sans-v18-latin-600.svg#OpenSans') format('svg');
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('open-sans-v18-latin-600italic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('open-sans-v18-latin-600italic.eot?#iefix') format('embedded-opentype'),
    url('open-sans-v18-latin-600italic.woff2') format('woff2'), url('open-sans-v18-latin-600italic.woff') format('woff'),
    url('open-sans-v18-latin-600italic.ttf') format('truetype'),
    url('open-sans-v18-latin-600italic.svg#OpenSans') format('svg');
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('open-sans-v18-latin-700.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    url('open-sans-v18-latin-700.woff2') format('woff2'), url('open-sans-v18-latin-700.woff') format('woff'),
    url('open-sans-v18-latin-700.ttf') format('truetype'), url('open-sans-v18-latin-700.svg#OpenSans') format('svg');
}
