// http://ionicframework.com/docs/theming/
@use '@ionic/angular/css/core.css';
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';

@use '@ionic/angular/css/padding.css';
@use '@ionic/angular/css/float-elements.css';
@use '@ionic/angular/css/text-alignment.css';
@use '@ionic/angular/css/text-transformation.css';
@use '@ionic/angular/css/flex-utils.css';
@use '@ionic/angular/css/display.css';

// common themes
@import 'app-header-styles';
@import 'classes/custom-amplify-auth';
@import 'classes/legal-info';
@import 'classes/reconnect';
@import 'classes/select-input';
@import 'classes/underlined-input';
@import 'focus';
@import 'fonts/open-sans/open-sans.scss';
@import 'mea-black-primary.scss';
@import 'mea-typography';
@import 'swiper/swiper-bundle.css';
@import 'utility-classes';

// pharmacy themes
@import './theme/alert';
@import './theme/print';
@import './theme/chat-wrapper';
@import './theme/login';

body,
app-root {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular) !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

ion-title {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
}

ion-modal {
  &.textblock-modal {
    --width: 90%;
    --min-width: 750px;
    --max-width: 1000px;
    --height: 70%;
    --max-height: 600px;
    --border-radius: var(--mea-border-radius-presentational);

    @media screen and (max-width: 1400px) {
      --width: 80%;
    }

    @media screen and (max-width: 1000px) {
      --width: 90%;
    }
  }

  &.textblock-form-modal {
    --height: auto;
  }
}

ion-popover.chat-category-popover {
  --width: auto;
}
