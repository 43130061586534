.underlined-input {
  border: none;
  border-bottom: 1px solid var(--mea-black-500);
  padding: 0 0 var(--mea-spacing-4px);
  margin: 0;

  ::placeholder {
    color: var(--mea-black-400);
  }

  &.focus {
    border-color: var(--mea-black-300);
  }
}
