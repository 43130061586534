.reconnect {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--mea-white);
  color: var(--mea-black-300);
  z-index: 1024;
  h1 {
    font-size: var(--mea-font-size-l);
    font-weight: var(--mea-font-weight-bold);
    text-align: center;
    padding: 0 var(--mea-spacing-60px);
  }
  img {
    width: 30%;
    height: auto;
  }
}
