.pharmacy-login-center {
  width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  .logo {
    width: 120px;
    padding-bottom: var(--mea-spacing-24px);
  }

  &.wide {
    width: 70vw;
    max-width: 700px;
    min-height: 100px;
    height: 90vh;
    top: 45%;
    overflow: auto;
    display: flex;
    align-items: center;

    .scroll-container {
      max-height: 100%;
    }
  }
}

.login-dialog-title {
  font-size: var(--mea-font-size-l);
  font-weight: var(--mea-font-weight-regular);
  flex-grow: 1;
  text-align: left;
  width: calc(100% - 64px);
  margin: var(--mea-spacing-16px) var(--mea-spacing-16px) var(--mea-spacing-8px) var(--mea-spacing-16px);
  &.bold {
    font-size: var(--mea-font-size-m);
    font-weight: var(--mea-font-weight-bold);
  }
}
