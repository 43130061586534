.mea-headline-xl {
  font-size: var(--mea-font-size-xl);
  font-weight: var(--mea-font-weight-bold);
}

.mea-headline-l,
.mea-title-headline-l {
  font-size: var(--mea-font-size-l);
  font-weight: var(--mea-font-weight-bold);
}

.mea-title-headline-l,
.mea-title-alignment {
  margin: var(--mea-spacing-16px) 0 var(--mea-spacing-16px);
  padding-inline: var(--mea-spacing-16px);
}

.mea-headline-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
}

.mea-headline-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-bold);
}

.mea-label-l {
  font-size: var(--mea-font-size-l);
  font-weight: var(--mea-font-weight-regular);
}

.mea-label-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular);
}

.mea-label-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-regular);
}

.mea-text-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular);
  line-height: var(--mea-line-height-medium);
}

.mea-text-m-bold {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
  line-height: var(--mea-line-height-medium);
}

.mea-text-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-regular);
  line-height: var(--mea-line-height-small);
}

.mea-text-s-bold {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-bold);
  line-height: var(--mea-line-height-small);
}
