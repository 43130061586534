ion-popover:not(.popover-backdrop) {
  --backdrop-opacity: 0;
}

ion-popover.app-datetime-popover {
  --width: 320px;
  --height: auto;
  box-shadow: var(--mea-box-shadow);

  &.calendar-overview {
    --offset-y: calc(-1 * var(--mea-size-interactive) - var(--mea-spacing-12px));
    --offset-x: calc(var(--mea-spacing-4px));
  }
}
