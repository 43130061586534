@import '../variables';

.legal-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: var(--mea-spacing-24px);
  margin-top: var(--mea-spacing-16px);
  flex-wrap: wrap;
  justify-content: space-around;

  span {
    margin: 0 var(--mea-spacing-8px);
    text-align: center;
    flex: 1 0 auto;
    text-decoration: underline;
    display: block;
    height: var(--mea-spacing-24px);
    line-height: var(--mea-spacing-24px);
    cursor: pointer;
    @media screen and (max-width: $small) {
      font-size: var(--mea-font-size-sm);
    }
  }
  &.bottom {
    position: absolute;
    bottom: var(--mea-spacing-16px);
  }
}
