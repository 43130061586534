ion-alert {
  .alert-group-button {
    flex-direction: row;
  }

  .alert-button {
    &.primary {
      background-color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      color: var(--mea-white);
      text-transform: none;
      border-radius: var(--mea-border-radius-interactive);
      font-size: var(--mea-font-size-m);
      font-weight: var(--mea-font-weight-bold);
      padding-left: var(--mea-spacing-16px);
      padding-right: var(--mea-spacing-16px);
    }

    &.danger {
      background-color: var(--ion-color-danger);
      border: 1px solid var(--ion-color-danger);
      color: var(--mea-white);
      text-transform: none;
      border-radius: var(--mea-border-radius-interactive);
      font-size: var(--mea-font-size-m);
      font-weight: var(--mea-font-weight-bold);
      padding-left: var(--mea-spacing-24px);
      padding-right: var(--mea-spacing-24px);
    }

    &.secondary {
      border: 1px solid var(--ion-color-primary);
      border-radius: var(--mea-border-radius-interactive);
      background: var(--mea-white);
      text-transform: none;
      color: var(--ion-color-primary);
      font-size: var(--mea-font-size-m);
      font-weight: var(--mea-font-weight-bold);
      padding-left: var(--mea-spacing-16px);
      padding-right: var(--mea-spacing-16px);
    }
  }
}
