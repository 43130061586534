:root {
  color: var(--mea-black-primary);
}

ion-content {
  color: var(--mea-black-primary);
}

ion-title {
  color: var(--mea-black-primary);
}

ion-item {
  color: var(--mea-black-primary);
}

ion-label {
  color: var(--mea-black-primary);
}

mea-alert {
  color: var(--mea-black-primary);
}
