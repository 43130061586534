.ion-palette-dark {
  --ion-color-primary: #4d8dff;
  --ion-color-primary-rgb: 77, 141, 255;
  --ion-color-primary-contrast: #000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #447ce0;
  --ion-color-primary-tint: #5f98ff;
  --ion-color-secondary: #46b1ff;
  --ion-color-secondary-rgb: 70, 177, 255;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #3e9ce0;
  --ion-color-secondary-tint: #59b9ff;
  --ion-color-tertiary: #8482fb;
  --ion-color-tertiary-rgb: 132, 130, 251;
  --ion-color-tertiary-contrast: #000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #7472dd;
  --ion-color-tertiary-tint: #908ffb;
  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;
  --ion-color-warning: #ffce31;
  --ion-color-warning-rgb: 255, 206, 49;
  --ion-color-warning-contrast: #000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b52b;
  --ion-color-warning-tint: #ffd346;
  --ion-color-danger: #f24c58;
  --ion-color-danger-rgb: 242, 76, 88;
  --ion-color-danger-contrast: #000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #d5434d;
  --ion-color-danger-tint: #f35e69;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #fff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
}

.ion-palette-dark.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-background-color-step-50: #0d0d0d;
  --ion-background-color-step-100: #1a1a1a;
  --ion-background-color-step-150: #262626;
  --ion-background-color-step-200: #333333;
  --ion-background-color-step-250: #404040;
  --ion-background-color-step-300: #4d4d4d;
  --ion-background-color-step-350: #595959;
  --ion-background-color-step-400: #666666;
  --ion-background-color-step-450: #737373;
  --ion-background-color-step-500: #808080;
  --ion-background-color-step-550: #8c8c8c;
  --ion-background-color-step-600: #999999;
  --ion-background-color-step-650: #a6a6a6;
  --ion-background-color-step-700: #b3b3b3;
  --ion-background-color-step-750: #bfbfbf;
  --ion-background-color-step-800: #cccccc;
  --ion-background-color-step-850: #d9d9d9;
  --ion-background-color-step-900: #e6e6e6;
  --ion-background-color-step-950: #f2f2f2;
  --ion-text-color-step-50: #f2f2f2;
  --ion-text-color-step-100: #e6e6e6;
  --ion-text-color-step-150: #d9d9d9;
  --ion-text-color-step-200: #cccccc;
  --ion-text-color-step-250: #bfbfbf;
  --ion-text-color-step-300: #b3b3b3;
  --ion-text-color-step-350: #a6a6a6;
  --ion-text-color-step-400: #999999;
  --ion-text-color-step-450: #8c8c8c;
  --ion-text-color-step-500: #808080;
  --ion-text-color-step-550: #737373;
  --ion-text-color-step-600: #666666;
  --ion-text-color-step-650: #595959;
  --ion-text-color-step-700: #4d4d4d;
  --ion-text-color-step-750: #404040;
  --ion-text-color-step-800: #333333;
  --ion-text-color-step-850: #262626;
  --ion-text-color-step-900: #1a1a1a;
  --ion-text-color-step-950: #0d0d0d;
  --ion-item-background: #000000;
  --ion-card-background: #1c1c1d;
}

.ion-palette-dark.ios ion-modal {
  --ion-background-color: var(--ion-color-step-100, var(--ion-background-color-step-100));
  --ion-toolbar-background: var(--ion-color-step-150, var(--ion-background-color-step-150));
  --ion-toolbar-border-color: var(--ion-color-step-250, var(--ion-background-color-step-250));
}

.ion-palette-dark.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-background-color-step-50: #1e1e1e;
  --ion-background-color-step-100: #2a2a2a;
  --ion-background-color-step-150: #363636;
  --ion-background-color-step-200: #414141;
  --ion-background-color-step-250: #4d4d4d;
  --ion-background-color-step-300: #595959;
  --ion-background-color-step-350: #656565;
  --ion-background-color-step-400: #717171;
  --ion-background-color-step-450: #7d7d7d;
  --ion-background-color-step-500: #898989;
  --ion-background-color-step-550: #949494;
  --ion-background-color-step-600: #a0a0a0;
  --ion-background-color-step-650: #acacac;
  --ion-background-color-step-700: #b8b8b8;
  --ion-background-color-step-750: #c4c4c4;
  --ion-background-color-step-800: #d0d0d0;
  --ion-background-color-step-850: #dbdbdb;
  --ion-background-color-step-900: #e7e7e7;
  --ion-background-color-step-950: #f3f3f3;
  --ion-text-color-step-50: #f3f3f3;
  --ion-text-color-step-100: #e7e7e7;
  --ion-text-color-step-150: #dbdbdb;
  --ion-text-color-step-200: #d0d0d0;
  --ion-text-color-step-250: #c4c4c4;
  --ion-text-color-step-300: #b8b8b8;
  --ion-text-color-step-350: #acacac;
  --ion-text-color-step-400: #a0a0a0;
  --ion-text-color-step-450: #949494;
  --ion-text-color-step-500: #898989;
  --ion-text-color-step-550: #7d7d7d;
  --ion-text-color-step-600: #717171;
  --ion-text-color-step-650: #656565;
  --ion-text-color-step-700: #595959;
  --ion-text-color-step-750: #4d4d4d;
  --ion-text-color-step-800: #414141;
  --ion-text-color-step-850: #363636;
  --ion-text-color-step-900: #2a2a2a;
  --ion-text-color-step-950: #1e1e1e;
  --ion-item-background: #1e1e1e;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;
} 
:root {
  /** text **/
  --mea-line-height-button: 52px;
  --mea-line-height-medium: 27px;
  --mea-line-height-small: 22px;
  --mea-line-height-default: 1.3;
  /** font **/
  --ion-dynamic-font: initial;
  --ion-font-family: "Open Sans";
  --mea-font-size-xl: 30px;
  --mea-font-size-l: 20px;
  --mea-font-size-m: 16px;
  --mea-font-size-sm: 14px;
  --mea-font-size-s: 12px;
  --mea-font-size-icon-small: 20px;
  --mea-font-size-icon: 24px;
  --mea-font-size-icon-large: 30px;
  --mea-font-weight-bold: 700;
  --mea-font-weight-regular: 400;
  /** border-radius **/
  --mea-border-radius-interactive: 7px;
  --mea-border-radius-presentational: 7px;
  --mea-border-radius-large: 20px;
  --mea-border-radius-input: 26px;
  --mea-border-radius-sheet-modal: 38px;
  --mea-border-radius-circular: 1000px;
  /** sizing **/
  --mea-input-height-large: 52px;
  --mea-input-height-textarea: 223px;
  --mea-button-min-width: 150px;
  --mea-size-interactive: 44px;
  --mea-size-checkbox: 20px;
  /** spacing **/
  --mea-padding-default: 12px;
  --mea-spacing-2px: 2px;
  --mea-spacing-4px: 4px;
  --mea-spacing-8px: 8px;
  --mea-spacing-12px: 12px;
  --mea-spacing-16px: 16px;
  --mea-spacing-20px: 20px;
  --mea-spacing-24px: 24px;
  --mea-spacing-32px: 32px;
  --mea-spacing-40px: 40px;
  --mea-spacing-60px: 60px;
  --mea-spacing-34px: 34px;
  /** miscellaneous **/
  --mea-backdrop-opacity-sheet-modal: 0.7;
}

ion-button {
  --box-shadow: none;
  text-transform: none;
  letter-spacing: unset;
}

ion-checkbox {
  max-width: 100%;
  --size: var(--mea-size-checkbox);
  --border-radius: var(--mea-border-radius-interactive);
  --border-width: 1px;
  --border-color: var(--mea-black-primary);
}

ion-icon {
  font-size: 1.05rem;
}

ion-modal.auto-height {
  --height: auto;
  --max-height: 100%;
}
ion-modal.auto-height .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.auto-height ::part(content) {
  overflow: auto;
}
ion-modal.auto-width {
  --width: auto;
  --max-width: 100%;
}
ion-modal.auto-width .ion-page {
  position: relative;
  display: flex;
  justify-content: flex-start;
  contain: content;
}
ion-modal.auto-width ::part(content) {
  overflow: auto;
}
ion-modal.full-screen-modal {
  --height: 100%;
  --width: 100%;
}
ion-modal.ion-sheet-modal-auto-height {
  --height: auto;
  --background: transparent;
}
ion-modal.right-edge {
  --height: 100%;
  --width: 420px;
  justify-content: flex-end;
}
ion-modal.product-search-modal {
  --min-height: 586px;
  --max-width: 100%;
  --width: 832px;
}
ion-modal.video-modal {
  --width: 70%;
}
ion-modal.mea-alert {
  --height: auto;
  --width: 400px;
}
ion-modal.mea-alert .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.enduser-feedback-modal ::part(content) {
  background-color: var(--mea-black-800);
  border-radius: 23px;
}
@media only screen and (width < 768px) {
  ion-modal.enduser-feedback-modal {
    align-items: flex-end;
  }
  ion-modal.enduser-feedback-modal .modal-shadow,
  ion-modal.enduser-feedback-modal ::part(content) {
    min-height: 320px;
    border-radius: 23px 23px 0 0;
  }
}
@media only screen and (min-width: 768px) {
  ion-modal.chat-image-slides-modal {
    --width: 100%;
    --height: 100%;
  }
}
@media only screen and (min-width: 768px) {
  ion-modal.dataconsent-modal {
    --border-radius: var(--mea-border-radius-presentational);
  }
}

ion-popover:not(.popover-backdrop) {
  --backdrop-opacity: 0;
}

ion-popover.app-datetime-popover {
  --width: 320px;
  --height: auto;
  box-shadow: var(--mea-box-shadow);
}
ion-popover.app-datetime-popover.calendar-overview {
  --offset-y: calc(-1 * var(--mea-size-interactive) - var(--mea-spacing-12px));
  --offset-x: calc(var(--mea-spacing-4px));
}

ion-radio {
  --border-width: 1px;
  --color: var(--mea-black-100);
}

ion-title {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
}

ion-toggle::part(label) {
  margin: 0 var(--mea-spacing-8px) 0 0;
  white-space: normal;
}

ion-toast.ion-toast-styling {
  --background: var(--mea-black-primary);
  --button-color: var(--mea-white);
  --border-radius: var(--mea-border-radius-interactive);
  --color: var(--mea-white);
  --start: var(--mea-spacing-16px);
  --end: var(--mea-spacing-16px);
}
ion-toast.ion-toast-styling::part(button) {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-bold);
}

:root {
  /** mea colors **/
  --mea-green-100: #034328;
  --mea-green-200: #058550;
  --mea-green-300: #69b696;
  --mea-green-400: #9bceb9;
  --mea-green-500: #cce5df;
  --mea-green-600: #e6f2ef;
  --mea-black-100: #000000;
  --mea-black-200: #333333;
  --mea-black-200-rgb: 51, 51, 51;
  --mea-black-300: #575756;
  --mea-black-400: #adadad;
  --mea-black-500: #d6d6d6;
  --mea-black-600: #ebebeb;
  --mea-black-700: #f7f7f7;
  --mea-black-800: #f9f9f9;
  --mea-black-800-rgb: 249, 249, 249;
  --mea-red-100: #620f1b;
  --mea-red-200: #c41e35;
  --mea-red-300: #d04b5d;
  --mea-red-400: #dc7886;
  --mea-red-500: #e7a5ae;
  --mea-red-600: #f3d2d7;
  --sanacorp-red: #c8005a;
  --sanacorp-red-shade: #b0004f;
  --sanacorp-red-tint: #ce1a6b;
  --sanacorp-red-contrast: var(--mea-white);
  --mea-blue: #2c4a9a;
  --mea-yellow: #f7a70a;
  --mea-logo: #97bf0d;
  --mea-green-primary: var(--mea-green-200);
  --mea-black-primary: var(--mea-black-200);
  --mea-black-primary-rgb: var(--mea-black-200-rgb);
  --mea-background: #f3f5f8;
  --mea-white: #ffffff;
  --mea-white-rgb: 255, 255, 255;
  --mea-background-popover: var(--mea-white);
  --mea-black-rgb: 0, 0, 0;
  --mea-black-static: var(--mea-black-200);
  --mea-white-static: var(--mea-white);
  --mea-background-dynamic: var(--mea-background);
  --mea-white-dynamic: var(--mea-white);
  --mea-black-600-dynamic: var(--mea-black-600);
  --mea-green-primary-light: var(--mea-green-primary);
  --mea-green-primary-dark: var(--mea-green-400);
  --mea-validation-error-color: var(--mea-red-200);
  /** ion colors **/
  --ion-color-primary: var(--mea-green-primary);
  --ion-color-primary-rgb: 5, 133, 80;
  --ion-color-primary-contrast: var(--mea-white);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--mea-green-100);
  --ion-color-primary-tint: var(--mea-green-300);
  --ion-color-secondary: var(--mea-green-300);
  --ion-color-secondary-rgb: 105, 182, 150;
  --ion-color-secondary-contrast: var(--mea-white);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--mea-green-200);
  --ion-color-secondary-tint: var(--mea-green-400);
  --ion-color-tertiary: var(--mea-green-100);
  --ion-color-tertiary-rgb: 3, 67, 40;
  --ion-color-tertiary-contrast: var(--mea-white);
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #033b23;
  --ion-color-tertiary-tint: var(--mea-green-200);
  --ion-color-success: var(--mea-green-300);
  --ion-color-success-rgb: 105, 182, 150;
  --ion-color-success-contrast: var(--mea-white);
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: var(--mea-green-200);
  --ion-color-success-tint: var(--mea-green-400);
  --ion-color-warning: var(--mea-yellow);
  --ion-color-warning-rgb: 247, 167, 10;
  --ion-color-warning-contrast: var(--mea-white);
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #d99309;
  --ion-color-warning-tint: #f8b023;
  --ion-color-danger: var(--mea-red-200);
  --ion-color-danger-rgb: 196, 30, 53;
  --ion-color-danger-contrast: var(--mea-white);
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: var(--mea-red-100);
  --ion-color-danger-tint: var(--mea-red-300);
  --ion-color-info: var(--mea-blue);
  --ion-color-info-rgb: 44, 74, 154;
  --ion-color-info-contrast: var(--mea-white);
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #274188;
  --ion-color-info-tint: #415ca4;
  /** legacy colors **/
  --ion-color-light: var(--mea-background);
  --ion-color-light-rgb: 243, 245, 248;
  --ion-color-light-contrast: var(--mea-black-100);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d6d8da;
  --ion-color-light-tint: #f4f6f9;
  /** background **/
  --ion-background-color: var(--mea-white);
  --ion-background-color-rgb: var(--mea-white-rgb);
  --ion-overlay-background-color: var(--mea-background);
  --ion-toolbar-background: var(--mea-white);
  --ion-item-background: var(--ion-background-color);
  --ion-text-color: var(--mea-black-primary);
  --ion-text-color-rgb: var(--mea-black-primary-rgb);
  --mea-box-shadow: 1px 2px 7px var(--mea-black-400);
  --mea-box-shadow-soft: 1px 2px 12px 0px var(--mea-black-400);
  --mea-dark-mode-border: none;
}
:root.ion-palette-dark {
  --mea-green-100: #c8e4d9;
  --mea-green-200: #9bceb9;
  --mea-green-300: #69b595;
  --mea-green-400: #43896c;
  --mea-green-500: #2d4038;
  --mea-green-600: #19241f;
  --mea-black-100: #f7f7f7;
  --mea-black-200: #e8e8e8;
  --mea-black-200-rgb: 232, 232, 232;
  --mea-black-300: #b2b2b2;
  --mea-black-400: #606060;
  --mea-black-500: #454545;
  --mea-black-600: #333333;
  --mea-black-700: #2d2d2d;
  --mea-black-700-rgb: 45, 45, 45;
  --mea-black-800: #232323;
  --mea-black-800-rgb: 35, 35, 35;
  --mea-red-100: #e7a5ae;
  --mea-red-200: #dc7886;
  --mea-red-300: #d04b5d;
  --mea-red-400: #c41e35;
  --mea-red-500: #620f1b;
  --mea-red-600: #310b0d;
  --sanacorp-red: var(--mea-red-100);
  --sanacorp-red-contrast: var(--mea-black-600);
  --mea-blue: #8294c3;
  --mea-yellow: #ffe985;
  --mea-logo: #97bf0d;
  --mea-green-primary: var(--mea-green-200);
  --mea-black-primary: var(--mea-black-200);
  --mea-black-primary-rgb: var(--mea-black-200-rgb);
  --mea-background: var(--mea-black-600);
  --mea-white: var(--mea-black-700);
  --mea-white-rgb: var(--mea-black-700-rgb);
  --mea-background-popover: var(--mea-black-500);
  --mea-black-rgb: 255, 255, 255;
  --mea-black-static: var(--mea-black-600);
  --mea-white-static: #ffffff;
  --mea-background-dynamic: var(--mea-white);
  --mea-white-dynamic: var(--mea-background);
  --mea-black-600-dynamic: var(--mea-white);
  --mea-green-primary-light: #058550;
  --mea-green-primary-dark: var(--mea-green-primary);
  --mea-validation-error-color: var(--mea-red-200);
  /** ion colors **/
  --ion-color-primary: var(--mea-green-primary);
  --ion-color-primary-rgb: 155, 206, 185;
  --ion-color-primary-contrast: var(--mea-white);
  --ion-color-primary-contrast-rgb: 45, 45, 45;
  --ion-color-primary-shade: var(--mea-green-100);
  --ion-color-primary-tint: var(--mea-green-300);
  --ion-color-warning: var(--mea-yellow);
  --ion-color-warning-rgb: 255, 233, 133;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0cd75;
  --ion-color-warning-tint: #ffeb91;
  --ion-color-danger: var(--mea-red-200);
  --ion-color-danger-rgb: 220, 120, 134;
  --ion-color-danger-contrast: var(--mea-white);
  --ion-color-danger-contrast-rgb: 45, 45, 45;
  --ion-color-danger-shade: var(--mea-red-100);
  --ion-color-danger-tint: var(--mea-red-300);
  --ion-color-info: var(--mea-blue);
  --ion-color-info-rgb: 130, 148, 195;
  --ion-color-info-contrast: var(--mea-white);
  --ion-color-info-contrast-rgb: 45, 45, 45;
  --ion-color-new-shade: #7282ac;
  --ion-color-new-tint: #8f9fc9;
  /** legacy colors **/
  --ion-color-light: var(--mea-background);
  --ion-color-light-rgb: 51, 51, 51;
  --ion-color-light-contrast: var(--mea-black-100);
  --ion-color-light-contrast-rgb: 247, 247, 247;
  --ion-color-light-shade: #292929;
  --ion-color-light-tint: #474747;
  /** background **/
  --ion-background-color: var(--mea-white);
  --ion-background-color-rgb: var(--mea-white-rgb);
  --ion-overlay-background-color: var(--mea-background);
  --ion-toolbar-background: var(--mea-background);
  --ion-item-background: var(--ion-background-color);
  --ion-text-color: var(--mea-black-primary);
  --ion-text-color-rgb: var(--mea-black-primary-rgb);
  --mea-box-shadow: none;
  --mea-box-shadow-soft: none;
  --mea-dark-mode-border: solid 1px var(--mea-black-500);
  /** miscellaneous **/
}
:root.ion-palette-dark ion-alert,
:root.ion-palette-dark ion-action-sheet {
  --ion-background-color-step-550: var(--mea-black-primary);
  --ion-text-color-step-550: var(--mea-black-primary);
  --ion-background-color-step-850: var(--mea-black-primary);
  --ion-text-color-step-850: var(--mea-black-primary);
}
:root.ion-palette-dark ion-toggle {
  --track-background: rgba(255, 255, 255, 0.3);
}