.select-input {
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --border-color: var(--mea-black-500);

    ion-input {
      &.ion-touched.ion-invalid {
        --placeholder-color: var(--mea-validation-error-color);
        --placeholder-opacity: 1;
      }

      &.ion-touched.ng-invalid {
        --color: var(--mea-validation-error-color);
      }

      --highlight-color-focused: var(--mea-black-primary);
      --highlight-color-valid: var(--mea-black-primary);
      --highlight-color-invalid: var(--mea-validation-error-color);
      --placeholder-color: var(--mea-black-400);
      --placeholder-opacity: 1;
      margin-bottom: -5px;
    }
  }

  ion-item:has(> ion-input.has-focus) {
    --border-color: var(--mea-black-300);
  }
}
