@mixin light {
  /** mea colors **/
  --mea-green-100: #034328;
  --mea-green-200: #058550;
  --mea-green-300: #69b696;
  --mea-green-400: #9bceb9;
  --mea-green-500: #cce5df;
  --mea-green-600: #e6f2ef;

  --mea-black-100: #000000;
  --mea-black-200: #333333;
  --mea-black-200-rgb: 51, 51, 51;
  --mea-black-300: #575756;
  --mea-black-400: #adadad;
  --mea-black-500: #d6d6d6;
  --mea-black-600: #ebebeb;
  --mea-black-700: #f7f7f7;
  --mea-black-800: #f9f9f9;
  --mea-black-800-rgb: 249, 249, 249;

  --mea-red-100: #620f1b;
  --mea-red-200: #c41e35;
  --mea-red-300: #d04b5d;
  --mea-red-400: #dc7886;
  --mea-red-500: #e7a5ae;
  --mea-red-600: #f3d2d7;

  --sanacorp-red: #c8005a;
  --sanacorp-red-shade: #b0004f;
  --sanacorp-red-tint: #ce1a6b;
  --sanacorp-red-contrast: var(--mea-white);
  --mea-blue: #2c4a9a;
  --mea-yellow: #f7a70a;
  --mea-logo: #97bf0d;

  --mea-green-primary: var(--mea-green-200);
  --mea-black-primary: var(--mea-black-200);
  --mea-black-primary-rgb: var(--mea-black-200-rgb);
  --mea-background: #f3f5f8;
  --mea-white: #ffffff;
  --mea-white-rgb: 255, 255, 255;
  --mea-background-popover: var(--mea-white);
  --mea-black-rgb: 0, 0, 0;

  --mea-black-static: var(--mea-black-200);
  --mea-white-static: var(--mea-white);
  --mea-background-dynamic: var(--mea-background);
  --mea-white-dynamic: var(--mea-white);
  --mea-black-600-dynamic: var(--mea-black-600);

  --mea-green-primary-light: var(--mea-green-primary);
  --mea-green-primary-dark: var(--mea-green-400);
  --mea-validation-error-color: var(--mea-red-200);

  /** ion colors **/
  --ion-color-primary: var(--mea-green-primary);
  --ion-color-primary-rgb: 5, 133, 80;
  --ion-color-primary-contrast: var(--mea-white);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: var(--mea-green-100);
  --ion-color-primary-tint: var(--mea-green-300);

  --ion-color-secondary: var(--mea-green-300);
  --ion-color-secondary-rgb: 105, 182, 150;
  --ion-color-secondary-contrast: var(--mea-white);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: var(--mea-green-200);
  --ion-color-secondary-tint: var(--mea-green-400);

  --ion-color-tertiary: var(--mea-green-100);
  --ion-color-tertiary-rgb: 3, 67, 40;
  --ion-color-tertiary-contrast: var(--mea-white);
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #033b23;
  --ion-color-tertiary-tint: var(--mea-green-200);

  --ion-color-success: var(--mea-green-300);
  --ion-color-success-rgb: 105, 182, 150;
  --ion-color-success-contrast: var(--mea-white);
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: var(--mea-green-200);
  --ion-color-success-tint: var(--mea-green-400);

  --ion-color-warning: var(--mea-yellow);
  --ion-color-warning-rgb: 247, 167, 10;
  --ion-color-warning-contrast: var(--mea-white);
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #d99309;
  --ion-color-warning-tint: #f8b023;

  --ion-color-danger: var(--mea-red-200);
  --ion-color-danger-rgb: 196, 30, 53;
  --ion-color-danger-contrast: var(--mea-white);
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: var(--mea-red-100);
  --ion-color-danger-tint: var(--mea-red-300);

  --ion-color-info: var(--mea-blue);
  --ion-color-info-rgb: 44, 74, 154;
  --ion-color-info-contrast: var(--mea-white);
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #274188;
  --ion-color-info-tint: #415ca4;

  /** legacy colors **/
  --ion-color-light: var(--mea-background);
  --ion-color-light-rgb: 243, 245, 248;
  --ion-color-light-contrast: var(--mea-black-100);
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d6d8da;
  --ion-color-light-tint: #f4f6f9;

  /** background **/
  --ion-background-color: var(--mea-white);
  --ion-background-color-rgb: var(--mea-white-rgb);
  --ion-overlay-background-color: var(--mea-background);
  --ion-toolbar-background: var(--mea-white);
  --ion-item-background: var(--ion-background-color);
  --ion-text-color: var(--mea-black-primary);
  --ion-text-color-rgb: var(--mea-black-primary-rgb);
  --mea-box-shadow: 1px 2px 7px var(--mea-black-400);
  --mea-box-shadow-soft: 1px 2px 12px 0px var(--mea-black-400);
  --mea-dark-mode-border: none;
}

@mixin dark {
  --mea-green-100: #c8e4d9;
  --mea-green-200: #9bceb9;
  --mea-green-300: #69b595;
  --mea-green-400: #43896c;
  --mea-green-500: #2d4038;
  --mea-green-600: #19241f;

  --mea-black-100: #f7f7f7;
  --mea-black-200: #e8e8e8;
  --mea-black-200-rgb: 232, 232, 232;
  --mea-black-300: #b2b2b2;
  --mea-black-400: #606060;
  --mea-black-500: #454545;
  --mea-black-600: #333333;
  --mea-black-700: #2d2d2d;
  --mea-black-700-rgb: 45, 45, 45;
  --mea-black-800: #232323;
  --mea-black-800-rgb: 35, 35, 35;

  --mea-red-100: #e7a5ae;
  --mea-red-200: #dc7886;
  --mea-red-300: #d04b5d;
  --mea-red-400: #c41e35;
  --mea-red-500: #620f1b;
  --mea-red-600: #310b0d;

  --sanacorp-red: var(--mea-red-100);
  --sanacorp-red-contrast: var(--mea-black-600);
  --mea-blue: #8294c3;
  --mea-yellow: #ffe985;
  --mea-logo: #97bf0d;

  --mea-green-primary: var(--mea-green-200);
  --mea-black-primary: var(--mea-black-200);
  --mea-black-primary-rgb: var(--mea-black-200-rgb);
  --mea-background: var(--mea-black-600);
  --mea-white: var(--mea-black-700);
  --mea-white-rgb: var(--mea-black-700-rgb);
  --mea-background-popover: var(--mea-black-500);
  --mea-black-rgb: 255, 255, 255;

  --mea-black-static: var(--mea-black-600);
  --mea-white-static: #ffffff;
  --mea-background-dynamic: var(--mea-white);
  --mea-white-dynamic: var(--mea-background);
  --mea-black-600-dynamic: var(--mea-white);

  --mea-green-primary-light: #058550;
  --mea-green-primary-dark: var(--mea-green-primary);
  --mea-validation-error-color: var(--mea-red-200);

  /** ion colors **/
  --ion-color-primary: var(--mea-green-primary);
  --ion-color-primary-rgb: 155, 206, 185;
  --ion-color-primary-contrast: var(--mea-white);
  --ion-color-primary-contrast-rgb: 45, 45, 45;
  --ion-color-primary-shade: var(--mea-green-100);
  --ion-color-primary-tint: var(--mea-green-300);

  --ion-color-warning: var(--mea-yellow);
  --ion-color-warning-rgb: 255, 233, 133;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0cd75;
  --ion-color-warning-tint: #ffeb91;

  --ion-color-danger: var(--mea-red-200);
  --ion-color-danger-rgb: 220, 120, 134;
  --ion-color-danger-contrast: var(--mea-white);
  --ion-color-danger-contrast-rgb: 45, 45, 45;
  --ion-color-danger-shade: var(--mea-red-100);
  --ion-color-danger-tint: var(--mea-red-300);

  --ion-color-info: var(--mea-blue);
  --ion-color-info-rgb: 130, 148, 195;
  --ion-color-info-contrast: var(--mea-white);
  --ion-color-info-contrast-rgb: 45, 45, 45;
  --ion-color-new-shade: #7282ac;
  --ion-color-new-tint: #8f9fc9;

  /** legacy colors **/
  --ion-color-light: var(--mea-background);
  --ion-color-light-rgb: 51, 51, 51;
  --ion-color-light-contrast: var(--mea-black-100);
  --ion-color-light-contrast-rgb: 247, 247, 247;
  --ion-color-light-shade: #292929;
  --ion-color-light-tint: #474747;

  /** background **/
  --ion-background-color: var(--mea-white);
  --ion-background-color-rgb: var(--mea-white-rgb);
  --ion-overlay-background-color: var(--mea-background);
  --ion-toolbar-background: var(--mea-background);
  --ion-item-background: var(--ion-background-color);
  --ion-text-color: var(--mea-black-primary);
  --ion-text-color-rgb: var(--mea-black-primary-rgb);
  --mea-box-shadow: none;
  --mea-box-shadow-soft: none;
  --mea-dark-mode-border: solid 1px var(--mea-black-500);

  /** miscellaneous **/
  ion-alert,
  ion-action-sheet {
    --ion-background-color-step-550: var(--mea-black-primary);
    --ion-text-color-step-550: var(--mea-black-primary);
    --ion-background-color-step-850: var(--mea-black-primary);
    --ion-text-color-step-850: var(--mea-black-primary);
  }
  ion-toggle {
    --track-background: rgba(255, 255, 255, 0.3);
  }
}

:root {
  @include light;

  &.ion-palette-dark {
    @include dark;
  }
}
