.amplify-form-message-hint {
  clear: both;
  width: 100%;
  padding: 0 var(--mea-spacing-16px);
  margin-top: var(--mea-spacing-8px);
  font-size: var(--mea-font-size-sm);
}

.amplify-form-container {
  height: 100%;
  min-height: 100%;
  overflow: auto;

  &.reversed {
    display: flex;
    flex-direction: column-reverse;
  }
}

ion-button {
  margin: var(--mea-spacing-16px);
  font-size: var(--mea-font-size-m);

  &.button-solid {
    font-weight: var(--mea-font-weight-bold);
  }
}

.horizontal-aligner {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.action-button-container {
  display: flex;
  flex-direction: column;
  gap: var(--mea-spacing-16px);
  align-items: center;
}
