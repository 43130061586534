@media print {
  // HomePage
  mea-error-header,
  app-header,
  app-side-navigation,
  .reconnect,
  .loading,
  mea-custom-alert {
    display: none !important;
  }

  // ChatPage
  .chat-list-container .contacts {
    display: none !important;
  }

  // ChatComponent
  app-chat-input {
    display: none !important;
  }

  // PharmacyChatHeaderComponent
  pharmacy-chat-header .chat-actions {
    display: none !important;
  }

  // ChatMessagesComponent
  open-ticket-reminder,
  .reopen-ticket-button,
  common-typing {
    display: none !important;
  }

  .print-only {
    display: contents !important;
  }

  * {
    overflow: visible !important;
  }

  body {
    display: contents !important;
  }

  .home-routing {
    position: static !important;
  }

  ion-router-outlet {
    contain: none;
    overflow: visible !important;
  }

  .ion-page {
    contain: none;
  }

  ion-content {
    --overflow: visible !important;
  }

  app-chat-messages {
    display: inline;
    overflow: visible;
  }

  .chat-list-container,
  .chat,
  .chat-wrapper .chat-container,
  .chat-wrapper .chat-container > .messages,
  app-chat-messages,
  app-chat-messages > .messages {
    height: auto !important;
    overflow: visible !important;
    display: inline !important;

    @-moz-document url-prefix() {
      display: flex !important;
    }

    .messages {
      margin: 0 var(--mea-spacing-16px);
    }
    .messages .chat-message-container {
      page-break-inside: avoid;
      break-inside: avoid;
      @-moz-document url-prefix() {
        margin-bottom: var(--mea-spacing-8px) !important;
      }
      * {
        page-break-inside: avoid;
        break-inside: avoid;
      }
      margin: 0;
      &.mine {
        .chat-message-box-container .chat-message-box {
          background-color: transparent !important;
          border: 1px solid rgb(182, 182, 182) !important;
        }
        &.pending {
          border: 1px solid red !important;
        }
      }
      &:not(.mine) {
        .chat-message-box-container .chat-message-box {
          background-color: transparent !important;
          border: 1px solid black !important;
        }
      }
    }
  }

  page-home {
    display: initial !important;
    page-chat {
      display: initial !important;
      .chat-list-container {
        display: initial !important;
        .chat-wrapper {
          &.ion-page {
            position: initial !important;
          }
          &:not(.ion-page-hidden) {
            display: initial !important;
            app-chat {
              display: initial !important;
              .chat-container {
                display: initial !important;
                .messages {
                  display: initial !important;
                  app-chat-messages {
                    display: initial !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // for printing images
  ion-modal,
  ion-popover {
    display: none;
  }
}
